@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bgc: #171717;
  --color: #fffcfa;
  --bgHero: url('cover.jpg');
  --bgProfile: url('logo.svg');
  --borderColor: #D23031;
  --gradient: linear-gradient(90deg, #D23031 0.02%, #a20404 100.02%);
  --font:'Changa', sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bgc);
  color: var(--color);
  font-family: var(--font);
}

.btn {
  height: 46px;
  width: 204px;
  border-radius: 5px;
  background: var(--gradient);
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  color: #fff;
  font-weight: 500;
}
.svc-btn{
  background: transparent;
  color: var(--borderColor);
  border: 2px solid var(--borderColor);
}
.svc-btn:hover{
  background: var(--gradient);
  color: #fff;
  border: 0px solid #fff;
}
h1 {
  font-size: 35px;
  font-weight: 500;
}

p {
  font-size: 20px;
  font-weight: 300;
}
.Footer a{
  color: var(--borderColor);
}